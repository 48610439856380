var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('div',[_c('div',{staticClass:"container-fluid p-0"},[_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-lg-4"},[_c('div',{staticClass:"authentication-page-content p-4 d-flex align-items-center min-vh-100"},[_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-9"},[_c('div',[_vm._m(1),(_vm.notification.message)?_c('b-alert',{staticClass:"mt-3",attrs:{"variant":_vm.notification.type,"dismissible":"","show":""},on:{"dismissed":function($event){return _vm.$store.dispatch('notification/clear')}}},[_vm._v(_vm._s(_vm.notification.message))]):_vm._e(),_c('div',{staticClass:"p-2 mt-5"},[(!_vm.showOtp)?_c('form',{staticClass:"form-horizontal",on:{"submit":function($event){$event.preventDefault();return _vm.tryToLogIn($event)}}},[_c('div',{staticClass:"form-group auth-form-group-custom mb-4"},[_c('i',{staticClass:"ri-mail-line auti-custom-input-icon"}),_c('label',{attrs:{"for":"username"}},[_vm._v("Username")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.username),expression:"username"}],staticClass:"form-control",class:{
                              'is-invalid':
                                (_vm.submitted && _vm.$v.username.$error) ||
                                _vm.errors.username !== '',
                            },attrs:{"type":"text","id":"username","placeholder":"Masukan username"},domProps:{"value":(_vm.username)},on:{"input":function($event){if($event.target.composing){ return; }_vm.username=$event.target.value}}}),(_vm.errors.username !== '')?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.errors.username))])]):_vm._e(),(_vm.submitted && _vm.$v.username.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.username.required)?_c('span',[_vm._v("username is required.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group auth-form-group-custom mb-4"},[_c('i',{staticClass:"ri-lock-2-line auti-custom-input-icon"}),_c('label',{attrs:{"for":"userpassword"}},[_vm._v("Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-control",class:{
                              'is-invalid': _vm.submitted && _vm.$v.password.$error,
                            },attrs:{"type":"password","id":"userpassword","placeholder":"Masukan password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),(_vm.submitted && !_vm.$v.password.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Password is required. ")]):_vm._e()]),_vm._m(2),_vm._m(3),_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('div',{attrs:{"id":"g_id_onload","data-client_id":_vm.googleClientId,"data-context":"signin","data-ux_mode":"popup","data-login_uri":_vm.loginUrl,"data-nonce":"","data-itp_support":"true"}}),_c('div',{staticClass:"g_id_signin",attrs:{"data-type":"standard","data-shape":"pill","data-theme":"filled_black","data-text":"signin_with","data-size":"large","data-logo_alignment":"left","data-width":"200"}})])]):_vm._e(),(_vm.showOtp)?_c('form',{staticClass:"form-horizontal",on:{"submit":function($event){$event.preventDefault();return _vm.verifyOtp($event)}}},[_c('p',{staticClass:"text-center"},[_vm._v(" Masukan kode OTP yang telah dikirim ke email Anda. ")]),_c('div',{staticClass:"form-group auth-form-group-custom mb-4"},[_c('i',{staticClass:"ri-lock-2-line auti-custom-input-icon"}),_c('label',{attrs:{"for":"otp"}},[_vm._v("OTP")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.otp),expression:"otp"}],staticClass:"form-control",class:{
                              'is-invalid':
                                (_vm.submitted && _vm.$v.otp.$error) ||
                                _vm.errors.otp !== '',
                            },attrs:{"type":"text","id":"otp","placeholder":"Masukan OTP"},domProps:{"value":(_vm.otp)},on:{"input":function($event){if($event.target.composing){ return; }_vm.otp=$event.target.value}}}),(_vm.errors.otp !== '')?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.errors.otp))])]):_vm._e(),(_vm.submitted && _vm.$v.otp.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.otp.required)?_c('span',[_vm._v("Otp is required.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"mt-4 text-center"},[(_vm.countdown > 0)?_c('p',[_vm._v(" Kirim ulang OTP dalam "+_vm._s(_vm.displayTime)+" ")]):_vm._e(),(_vm.countdown === 0)?_c('button',{staticClass:"btn btn-primary",attrs:{"disabled":_vm.countdown > 0},on:{"click":_vm.actionResendOtp}},[_vm._v(" Kirim Ulang OTP ")]):_vm._e()]),_vm._m(4)]):_vm._e()]),_vm._m(5)],1)])])])])]),_vm._m(6)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home-btn d-none d-sm-block"},[_c('a',{attrs:{"href":"/"}},[_c('i',{staticClass:"mdi mdi-home-variant h2 text-white"})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('div',[_c('img',{attrs:{"src":require("@/assets/images/logo/logo-text.png"),"height":"100","alt":"logo"}})]),_c('h4',{staticClass:"font-size-18 mt-4"},[_vm._v("Selamat datang !")]),_c('p',{staticClass:"text-muted"},[_vm._v(" Masuk untuk melanjutkan ke bolu. ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-4 text-center"},[_c('button',{staticClass:"btn btn-primary w-md waves-effect waves-light",attrs:{"type":"submit"}},[_vm._v(" Masuk ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"text-center mt-4"},[_c('b',[_vm._v("Atau")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-4 text-center"},[_c('button',{staticClass:"btn btn-primary w-md waves-effect waves-light",attrs:{"type":"submit"}},[_vm._v(" Verifikasi OTP ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-5 text-center"},[_c('p',[_vm._v(" © 2021 Bolu ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg-8"},[_c('div',{staticClass:"authentication-bg"},[_c('div',{staticClass:"bg-overlay"})])])}]

export { render, staticRenderFns }